import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index-business',
  templateUrl: './index-business.component.html',
  styleUrls: ['./index-business.component.css']
})

/***
 * Business Component
 */
export class IndexBusinessComponent implements OnInit {

    // Set Topbar Option
  Menuoption = 'center';
  Settingicon = false
  /**
   * Services Data
   */
  

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 2,
    decimalPlaces: 0,
  };

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  openModal(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }


}
