<!-- Hero Start -->
<section class="bg-half-170 border-bottom d-table w-100" id="home">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-7">
        <div class="title-heading mt-4">
        
          <h1 class="heading mb-3">
            <ngx-typed-js [strings]="['Innovation', 'Strategy', 'Technology', 'Automation', 'Quality']" [shuffle]="true"
              [typeSpeed]="80" [loop]="true">
              We are your partners in <span class="element text-primary typing"></span> Solutions
            </ngx-typed-js>
          </h1>
          <p class="para-desc text-muted">
            When there is a problem we see opportunities to improve, if there is a gap we see a chance to try new solutions, when there is a need we apply the appropriate tools and if technology is the need we are the experts you are looking for.
          </p>
          <div class="mt-4">
            <a href="mailto:contact@biznnovate.com" class="btn btn-outline-primary rounded"><i class="uil uil-fast-mail"></i> Contact us</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="position-relative">
          <img src="assets/images/Biznnovate-Logo-120px.png" class="rounded img-fluid mx-auto d-block"  max-height="120px" alt="" />
          
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
